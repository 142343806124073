import React from "react";
import HomeLanding from "../HeroLanding";
import { Link } from "gatsby";
import OutlinedText from "../styles/OutlinedText";
import { Headline80 } from "../styles/Headings";
import HeroAwards from "../publishers/HeroAdwards";
import HeroNeverStopEarning from "../publishers/HeroNeverStopEarning";
import LandingShopsAnimations from "../publishers/LandingShopsAnimation";

const PublishersShopsStatic = () => {
  return (
    <>
      <HomeLanding
        section="shops for publishers"
        link={{
          label: "Get Started",
          attrs: { to: "/contact/?smopt=publisher", state: { from: "publisher" }, as: Link }
        }}
        bottomMargins="-30%"
        theme={{
          titleWidth: "900px",
          topPadding: "104px",
          topLargePadding: "200px",
          bottomPadding: "72px",
          bottom: "#fff",
          imageTranslate: "translate(calc(100% * 7.8 / 12), 58%)",
          imageBottom: "50%",
          mediaCss:
            "width: 100%; max-width: 100% !important; margin-bottom: 11%",
          imageMarginTop: 0,
          gatsbyWidth: "1000px"
        }}
        animationComponent={<LandingShopsAnimations />}
      >
        <OutlinedText fill={"transparent"} component={Headline80} as="span">
          supercharge{" "}
        </OutlinedText>
        the way you do commerce{" "}
      </HomeLanding>

      <HeroAwards />

      <HeroNeverStopEarning />
    </>
  );
};

export default PublishersShopsStatic;
