import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled, { css } from "styled-components";
import { mediaMedium } from "../styles/variables";
import Image from "../Image";
import { FadeSlideInRight } from "../styles/animations";

const Wrapper = styled.div`
  width: calc(100% + 40px);
  margin: 0 -20px;
  transform: translateX(-5%);
  z-index: 2;
  position: relative;
  padding: 22px 0 88px;

  @media (${mediaMedium}) {
    transform: none;
    padding: 0;
    position: absolute;
    width: 75%;
    max-width: 800px;
    top: 17%;
    right: -30%;
  }
`;

const Grid = styled.div`
  position: relative;
  z-index: 2;

  &:after {
    content: "";
    width: 1px;
    display: inline-block;
    padding-top: 86.5%;
  }

  &:before {
    content: "";
    z-index: 2;
    width: 888px;
    height: 30px;
    position: absolute;
    bottom: -20px;
    right: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.4) 80%,
      rgba(255, 255, 255, 0) 100%
    );

    @media (${mediaMedium}) {
      height: 60px;
    }
  }
`;

const Right = styled.div`
  position: absolute;
  width: 65%;
  left: 42%;
`;

const Left = styled(Right)`
  left: 0%;
`;

const Animation = styled(Image)`
  transform: translateX(30%);
  max-width: 100% !important;
  max-height: none !important;
  width: 100%;
  opacity: 0;
  animation: ${(props) =>
    props.active
      ? css`
          ${FadeSlideInRight} 0.9s ease forwards
        `
      : "none"};
  animation-delay: ${(props) => props.delay}s;
`;

const LandingShopsAnimations = () => {
  const Images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "39-commerce-landing-1.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      two: file(relativePath: { eq: "39-commerce-landing-2.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const [active, setActive] = useState(false);
  const handleLoad = () => {
    setActive(true);
  };

  return (
    <Wrapper>
      <Grid>
        <Left>
          <Animation
            onLoad={handleLoad}
            loading="eager"
            fluid={Images.one.childImageSharp.fluid}
            active={active}
            delay={0.2}
          />
        </Left>

        <Right>
          <Animation
            onLoad={handleLoad}
            loading="eager"
            fluid={Images.two.childImageSharp.fluid}
            active={active}
            delay={0.3}
          />
        </Right>
      </Grid>
    </Wrapper>
  );
};

export default LandingShopsAnimations;
