import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import loadable from "@loadable/component";
import LazyLoadSection from "../../components/LazyLoadSection";
import StaticSection from "../../components/sections/PublishersShopsStatic";

const AsyncSection = loadable(() =>
  import("../../components/sections/PublishersShopsDefer")
);

const Shops = () => (
  <Layout>
    <Seo
      title="Powering Branded Shops for Publishers"
      description="Boost your revenue with a branded eCommerce shop powered by StackCommerce. We help publishers monetize with end-to-end commerce and content solutions."
    />

    <LazyLoadSection static={<StaticSection />}>
      <AsyncSection />
    </LazyLoadSection>
  </Layout>
);

export default Shops;
