import React from "react";
import styled from "styled-components";
import HeroTwoColumns from "../HeroTwoColumns";
import { gray4 } from "../styles/Colors";
import PagePadding from "../styles/PagePadding";
import HeroContentSnippet from "../HeroContentSnippet";
import OutlinedText from "../styles/OutlinedText";
import TextHighlight from "../styles/TextHighlight";
import Image from "../Image";
import LinesFromRight from "../LinesFromRight";
import { graphql, useStaticQuery } from "gatsby";
import { mediaMedium } from "../styles/variables";

const Display = styled.div`
  text-align: center;
  padding-bottom: 58px;

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  @media (${mediaMedium}) {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -41%;

    .gatsby-image-wrapper {
      flex: 1 1 100%;
      width: 100%;
    }
  }

  @media (min-width: 1100px) {
    .gatsby-image-wrapper {
      width: calc(100% + 160px);
      max-width: none !important;
      max-height: none !important;
      transform: translateX(80px);
    }
  }
`;

const Decoration = styled.div`
  display: none;
  pointer-events: none;

  @media (${mediaMedium}) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;
    transform: translate(74%, 92%);
    padding-bottom: 0;
  }
`;

const HeroNeverStopSection = ({ image }) => {
  return (
    <HeroTwoColumns
      background={gray4}
      gap="calc(100% * 1/12)"
      columns="calc(100% * 5.5/12) calc(100% * 6/12)"
      reverse={true}
      paddingMedia="15px 0 0"
    >
      <Decoration>
        <LinesFromRight skew="-24deg" />
      </Decoration>
      <PagePadding>
        <HeroContentSnippet
          padding="0 0 127px"
          marginTitle="-24px 0 -30px"
          title={
            <p>
              NEVER STOP{" "}
              <OutlinedText fill="transparent" as="span" component="span">
                EARNING
              </OutlinedText>
            </p>
          }
          noTrail={true}
          link={false}
        >
          <p>
            We believe publishers should earn higher commissions from affiliate
            content.
          </p>
          <p>
            That's why we negotiate directly with brands to bring you{" "}
            <TextHighlight theme={{ background: gray4 }}>
              3-5x higher commissions
            </TextHighlight>{" "}
            and best-in-market deals for your readers.
          </p>
          <p>
            And unlike traditional affiliate programs which require upfront
            budgets for writers, editors, and commerce experts, Stack scales
            your earnings with{" "}
            <TextHighlight theme={{ background: gray4 }}>
              zero out-of-pocket costs.
            </TextHighlight>
          </p>
        </HeroContentSnippet>
      </PagePadding>
      <Display>
        <Image fluid={image.file.childImageSharp.fluid} />
      </Display>
    </HeroTwoColumns>
  );
};

const HeroNeverStopEarning = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "41-never-stop-earning-cycle.png" }) {
        childImageSharp {
          fluid(maxWidth: 564, maxHeight: 564, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <HeroNeverStopSection image={data} />;
};

export default HeroNeverStopEarning;
