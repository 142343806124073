import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import Image from "../Image";
import { white } from "../styles/Colors";
import { graphql, useStaticQuery } from "gatsby";
import { Body18 } from "../styles/BodyTypes";
import { mediaMedium } from "../styles/variables";

const Wrapper = styled.div`
  background-color: ${white};
  padding: 3px 0 72px;
  position: relative;
  z-index: 1;

  @media (${mediaMedium}) {
    padding: 0 0 96px 0;
    margin-top: -80px;
  }
`;

const Grid = styled.div`
  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: repeat(3, 255px);
    grid-column-gap: 48px;
  }
`;

const Content = styled(Body18)`
  border-top: 1px solid #ababab;
  padding-top: 14px;
  margin: 17px 0 0;

  @media (${mediaMedium}) {
    padding-top: 18px;
    margin: 28px 0;
  }

  span {
    display: block;
    color: #ababab;
  }
`;

const Row = styled.div`
  &:not(:last-child) {
    margin-bottom: 46px;
  }

  @media (${mediaMedium}) {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const HeroAwardsSection = ({ data }) => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <Row>
            <Image fluid={data.technology.childImageSharp.fluid} />
            <Content>
              Best Affiliate Marketing Platform <span>2020 Finalist</span>
            </Content>
          </Row>
          <Row>
            <Image fluid={data.technology.childImageSharp.fluid} />
            <Content>
              Best eCommerce Content Platform <span>2019 Finalist</span>
            </Content>
          </Row>
          <Row>
            <Image fluid={data.publish.childImageSharp.fluid} />
            <Content>
              Best eCommerce Strategy <span>2017 Finalist</span>
            </Content>
          </Row>
        </Grid>
      </Container>
    </Wrapper>
  );
};

const HeroAwards = () => {
  const data = useStaticQuery(graphql`
    query {
      publish: file(relativePath: { eq: "40-digiday-award-publish.png" }) {
        childImageSharp {
          fluid(maxWidth: 164, maxHeight: 56, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      technology: file(
        relativePath: { eq: "40-digiday-award-technology.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 185, maxHeight: 56, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <HeroAwardsSection data={data} />;
};

export default HeroAwards;
